/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:5f49eaf1-c191-4391-8964-cff3a95d680e",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_YlIIH7b9R",
    "aws_user_pools_web_client_id": "1n9n51117fanmvokcq6pdhav1s",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://ae62dt3sba.execute-api.ca-central-1.amazonaws.com/stmar",
            "region": "ca-central-1"
        },
        {
            "name": "saveledgerentry",
            "endpoint": "https://sd0uvgz8q5.execute-api.ca-central-1.amazonaws.com/stmar",
            "region": "ca-central-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "ca-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ppetable-stmar",
            "region": "ca-central-1"
        },
        {
            "tableName": "locationinfo-stmar",
            "region": "ca-central-1"
        },
        {
            "tableName": "ppeledger-stmar",
            "region": "ca-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "qwantifyppecartprofile174216-stmar",
    "aws_user_files_s3_bucket_region": "ca-central-1",
    "aws_content_delivery_bucket": "qwppedev-stmar",
    "aws_content_delivery_bucket_region": "ca-central-1",
    "aws_content_delivery_url": "https://dvjixxs98ug3a.cloudfront.net"
};


export default awsmobile;
